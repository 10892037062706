/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable dot-notation */
import dynamic from 'next/dynamic';
import { ReactElement, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { ErrorAlert } from '@components/Alerts/ErrorAlert';
import { SuccessAlert } from '@components/Alerts/SuccessAlert';
import { Button, ButtonType } from '@components/Button';
import { Input } from '@components/FormComponents';
import { Paragraph } from '@components/Typography/Paragraph/Paragraph';
import { useKlaviyo } from '@hooks/useKlaviyo';
import { Variant } from '@interfaces/Product';
import { log } from '@lib/utils';

const Modal = dynamic(() =>
  import('../Modal/Modal').then((mod) => mod.ModalNoPortal)
);

export function NotifyStock({
  inStock,
  defaultVariantId,
  variants,
  name,
}: {
  inStock?: boolean;
  defaultVariantId?: number;
  variants: Variant[];
  name: string;
}): ReactElement {
  const [showForm, setShowForm] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const { notifyStockSubscribe } = useKlaviyo();

  const rhfMethods = useForm<any>({
    mode: 'onTouched',
    defaultValues: {
      email: '',
    },
  });

  const handleClick = async (e) => {
    setShowForm(true);
  };

  const handleSubscribe = async (e: { email: string }) => {
    try {
      const variant = defaultVariantId || variants[0].variantId;

      const success = await notifyStockSubscribe({
        email: e.email,
        variantId: variant,
      });

      if (success) {
        setSuccess(true);
      } else {
        setError(true);
      }
      setShowForm(false);
    } catch (err) {
      log({ error: err, location: 'NotifyStock handleSubscribe' });
      setError(true);
    }
  };

  return !inStock &&
    inStock !== undefined &&
    (variants.length > 0 || defaultVariantId) ? (
    <div className="mx-0 my-5">
      <Button type={ButtonType.button} onClick={handleClick}>
        Notify me when in stock
      </Button>
      {showForm && (
        <Modal show={showForm} setShow={setShowForm}>
          <section>
            <h3>{name}</h3>
            <Paragraph>
              Register to receive a notification when this item comes back in
              stock.
            </Paragraph>
            <FormProvider {...rhfMethods}>
              <form
                className="flex flex-col gap-4"
                onSubmit={rhfMethods.handleSubmit(handleSubscribe)}
              >
                <Input
                  label="Email Address"
                  name="email"
                  type="email"
                  required
                  placeholder="Enter your email address"
                />
                <Button type={ButtonType.submit}>
                  Notify me when available
                </Button>
              </form>
            </FormProvider>
          </section>
        </Modal>
      )}
      <SuccessAlert
        title=""
        message={`You are successfully subscribed and will receive an email when ${name} is back in stock`}
        show={success}
        setShow={setSuccess}
      />
      <ErrorAlert show={error} setShow={setError}>
        <Paragraph>Something went wrong trying to subscribe</Paragraph>
      </ErrorAlert>
    </div>
  ) : (
    <></>
  );
}
